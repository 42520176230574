/* ============================================================
   Variables
============================================================ */
:root {
  --main-orange: #E8A941;
  --main-orange-hover: #e5c48f;
  --nearly-black: #000112;
  --grey-6: #20212C;
  --grey-5: #2b2c37;
  --grey-4: #3e3f4e;
  --grey-3: #828fa3;
  --grey-2: #e4ebfa;
  --grey-1: #f4f7fd;
  --green: #15DC4D;
  --green-hover: #6fff98;
  --destructive-red: #ea5555;
  --destructive-red-hover: #ff9898;
}

/* ============================================================
   Global Styles
============================================================ */

/* Reset Margins and Padding */
p, h1, h2, h3, h4, h5, h6, label {
  margin: 0;
  padding: 0;
}




li, p {
  color: var(--grey-2);
  word-wrap: break-word; /* Allows long words to break and wrap */
  word-break: break-word; /* Breaks words at the end of the line */
  white-space: normal;    /* Ensures text wraps within the container */
  opacity: 0.8;
  font-weight: 300;
}

ul {
  list-style-type: disc;
}

li::marker {
  color: var(--main-orange);
  font-size: 1.1rem;
  opacity: 1;
}

button {
  border: none;
  font-weight: 700;
  font-size: 1.1rem;
  background: none;
  cursor: pointer;
}

body, button, input, textarea, select, option, h1, h2, h3, h4, a {
  font-family: "Nunito", sans-serif;
}

@font-face {
  font-family: 'reg-font';
  src: local('Nunito'),
       url('./assets/fonts/CharlesWright-Bold.otf') format('truetype');
  font-weight: normal;
  font-style: normal;
}

/* Scrollbar Styles */
::-webkit-scrollbar {
  width: 6px;  
}

::-webkit-scrollbar-track {
  background: var(--grey-4); 
}

::-webkit-scrollbar-thumb {
  background: #888; 
  border-radius: 8px; 
}

::-webkit-scrollbar-thumb:hover {
  background: #555;  
}

/* Remove Number Spinners */
input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type="number"] {
  -moz-appearance: textfield;
  -ms-appearance: textfield;
  appearance: textfield;
}

input[type="date"]::-webkit-inner-spin-button,
input[type="date"]::-webkit-calendar-picker-indicator {
  display: none;
  -webkit-appearance: none;
}

.no-scroll {
  overflow: hidden; /* Prevents both horizontal and vertical scrolling */
}

/* ============================================================
   Typography
============================================================ */

.heading-xxl {
  font-size: 1.35rem;
  font-weight: 600;
  color: white;
  letter-spacing: 0.7px;
}

.heading-xl {
  font-size: 1.3rem;
  font-weight: 400;
  color: white;
  letter-spacing: 0.7px;
}

.heading-xl {
  font-size: 1.063rem;
  font-weight: 400;
  color: white;
  letter-spacing: 0.7px;
}

.heading-l, h2 {
  font-size: 1.1rem;
  font-weight: 700;
  color: white;
  letter-spacing: 0.7px;
}

.heading-m, h3 {
  font-size: 1.1rem;
  font-weight: 500;
  color: white;
  letter-spacing: 0.7px;
}

.heading-s {
  letter-spacing: 0.7px;
  font-size: 1em;
  font-weight: 700;
  letter-spacing: 0.219rem;
}

.sub-heading-orange {
  font-weight: 400;
  font-size: 1.3em;
  color: var(--main-orange);
}

.body-l, p, ul, a {
  font-size: 1.1rem;
  font-weight: 300;
  line-height: 1.50rem;
  letter-spacing: 1px;
}

.body-s {
  font-size: 0.9rem;
  font-weight: 500;
  line-height: 1.2rem;
}

/* ============================================================
   Layout
============================================================ */

/* App Logo */
.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

/* General Container */
.main-wr {
  background-color: var(--grey-6);
  padding: 0.5em;
  margin-top: 4em;
  box-sizing: border-box;
  min-height: 100vh;
}

/* ============================================================
   Header
============================================================ */

header {
  background-color: var(--grey-5);
  padding: 0.7em;
  display: grid;
  grid-template-columns: 130px 1fr;
  align-items: center;
  justify-content: space-between;
  border-bottom: 2px solid var(--grey-4);
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  box-sizing: border-box;
  z-index: 200;
}

header .left-col {
  
}


header .right-col {
  display: flex;
  align-items: center;
  gap: 0.25em;
  margin-left: auto;
}

.logo {
  width: 120px;
  margin-left: 4px;
  margin-bottom: -5px;
}

.header-subtitle {
  margin-left: 3px;
  margin-top: 0.3em;
  font-size: 1rem;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.header-title {
  font-size: 1.1rem;
  text-align: right;
}

.info-icon {
  display: none;
}

/* ============================================================
   Sidebar
============================================================ */

/* Sidebar Wrapper */
.sidebar-wr {
  position: fixed;
  top: 0;
  left: -100%; /* Hidden initially */
  width: 100%;
  height: 100vh;
  background-color: var(--grey-5);
  padding: 0em 2em 1.5em 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
  z-index: 300;
  transition: transform 0.3s ease-in-out;
}

.sidebar-wr.show {
  transform: translateX(100%); /* Slide in */
}

.sidebar-wr .sidebar-nav-wr, 
.sidebar-wr .sidebar-nav-btns {
  display: flex;
  align-items: center;
}

.sidebar-wr .sidebar-nav-wr {
  margin-top: 0.5em;
}

.sidebar-wr .sidebar-nav-btns {
  column-gap: 1em;
  display: flex;
  margin-right: 1em;
  width: 100%;
  flex-wrap: wrap;
}

.sidebar-wr .top-row {
  display: flex;
  flex-direction: column;
  width: 100%;
  position: relative;
}

.sidebar-wr .logo {
  display: none;
}

.sidebar-wr .bike-list {
  margin-top: 0.5em;
  display: flex;
  flex-direction: column;
  gap: 0.5em;
}

.close-sidebar-icon {
  position: fixed;
  top: 1.6em;
  right: 1.5em;
  z-index: 1000;
  width: 30px;
}

.bike-count {
  color: var(--grey-3);
}

.sidebar-wr .bike-btn,
.sidebar-wr .add-bike-btn,
.sidebar-wr .nav-btn, 
.request-bike-btn {
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  color: var(--grey-3);
  background-color: var(--grey-5);
  height: 30px;
  box-sizing: border-box;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}


.sidebar-wr .request-bike-btn {
  margin-top: 0.5em;
  margin-left: -0.15em;
}

.sidebar-wr .bike-btn {
  height: 55px;
}

.sidebar-wr .home-btn,
.sidebar-wr .logbook-btn,
.sidebar-wr .add-bike-btn {
  justify-content: flex-start;
  text-decoration: underline;
  font-weight: 600;
}

.sidebar-wr .add-bike-btn {
  text-decoration: none;
  margin-top: 0.5em;
}

.sidebar-wr .nav-btn {
  text-decoration: none;
  letter-spacing: 1px;
  color: var(--grey-2);
  font-size: 1.3rem;
  font-weight: 600;
  margin-bottom: 0.2em;
}


.sidebar-wr .bike-btn {
  display: inline-block; 
  max-width: 100%;        
  white-space: nowrap;  
  overflow: hidden;       
  text-overflow:clip;
  text-align: start;
}

.sidebar-wr .selected {
  color: white;
  background-color: var(--main-orange);
  border-top-right-radius: 30px;
  border-bottom-right-radius: 30px;
  padding-left: 2.3em;
  padding-right: 1em;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  position:relative;
  left: -2em;
  width: calc(100% + 4em);
  margin-right: -2em;
}

.sidebar-wr .home-btn,
.sidebar-wr .logbook-btn {
  color: var(--grey-2);
}

.home-icon,
.logbook-icon {
  width: 20px;
  margin-right: 0.5em;
}

.bike-icon {
  width: 30px;
  margin-right: 0.5em;
}

.sidebar-wr .add-bike-btn {
  color: var(--main-orange);
}

.sidebar-nav-btns {
  margin: 1em 0 2em 0;
}

.sidebar-wr .bottom-row {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.sidebar-wr .bike-btn-row {
  width: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.sidebar-wr .delete-bike-btn {
  background-color: var(--grey-5);
  color: var(--destructive-red);
  font-size: 1.125rem;
}

.sidebar-wr .theme-selector-signout-wr {
  background-color: var(--grey-6);
  width: 250px;
  border-radius: 5px;
  padding: 0.5em 2em;
  display: flex;
  justify-content: center;
}

.sidebar-wr .theme-selector-wr {
  display: flex;
  gap: 1.5em;
}

.theme-selector-wr img {
  width: 20px;
}

#signout-btn {
  background: none;
  color: var(--grey-2);
  font-size: 1.125rem;
}

.sidebar-wr .hide-sb-btn {
  display: none;
  color: var(--grey-3);
  align-items: center;
  gap: 1em;
  background-color: var(--grey-5);
}

.sidebar-wr .add-bike-btn:hover {
  color: var(--grey-1);
}

.sidebar-wr .delete-bike-btn:hover {
  color: var(--destructive-red-hover);
}

.sidebar-wr .selected:hover {
  background-color: var(--main-orange-hover);
}

.sidebar-wr .bike-btn:hover {
  color: var(--grey-2);
}

#signout-btn:hover {
  color: var(--grey-3);
}

.request-bike-btn {
  color: var(--main-orange-hover);
}

.sidebar-transfer-log-icon {
  width: 25px;
  margin-right: 0.3em;
  margin-left: -0.15em;
}


/* ============================================================
   Main Content
============================================================ */

/* General Main Wrapper */
.main-wr {
  background-color: var(--grey-6);
  padding: 0.5em;
  margin-top: 4em;
  box-sizing: border-box;
  min-height: 100vh;
}

/* Maintenance Log */
.main-log-wr {
  padding: 0.5em;
}

.mobile-add-log-btn-wr {
  width: 100%;
  display: flex;
  justify-content: center;
}

.mobile-add-log-btn-wr .add-log-icon {
  width: 45px;
  margin-top: 0.3em;
  margin-bottom: 0.3em;
}

.verified-title-wr {
  display: flex;
  align-items: center;
  gap: 0.3em;
  margin-bottom: 1em;
}

.verified-log-title {
  padding: 0;
  margin: 0;
}

.verified-log-icon {
  width: 25px;
}

.logs-grid {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1em;
}

.log-wr {
  background-color: var(--grey-5);
  border-radius: 8px;
  padding: 2em 1em;
  position: relative;
}

.edit-btn {
  position: absolute;
  top: 2.2em;
  right: 1.2em;
  background-color: var(--main-orange);
  font-size: 0.9rem;
  display: flex;
  align-items: center;
  gap: .1em;
  border-radius: 5px;
  padding: 0 0.3em;
  font-weight: 800;
  letter-spacing: 1px;
}

.edit-icon {
  margin-top: -0.1em;
  margin-right: -0.15em;
  width: 19px;
}

.edit-btn:hover {
  background-color: var(--main-orange-hover);
}

.verified-bg:hover {
  background-color: var(--green-hover);
}

.log-date {
  margin-bottom: 0.6em;
  letter-spacing: 1.3px;
}

.log-header {
  margin-bottom: 1em;
  align-items: center;
}

.log-tasks-wr {
  margin-top: 1em;
}

.log-tasks-list {
  padding: 0 2em;
  position: relative;
  margin-top: 0.5em;
}

.cost-dealer-row-wr {
  display: flex;
  align-items: center;
  gap: 1em;
  margin-bottom: 0.7em;
}

.cost-dealer-row-wr h3 {
  margin-bottom: 0;
}

.log-additional-info {
  margin-bottom: 0.5em;
}

.log-notes {
  margin-top: 1em;
}

.log-photos-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 1em;
  margin-top: 1em;
}

.log-photo-container {
  position: relative;
}

.log-photo-wr {
  width: 100%;
  aspect-ratio: 6/6; 
  background-color: var(--grey-5);
  overflow: hidden;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}

.log-photo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: cover; 
  border-radius: 5px;
}

.photos-heading {
  display: flex;
  gap: 0.5em;
  align-items: center;
}

.photos-heading .heading-m {
  margin: 0;
}

.photos-heading .photos.count {
  font-size: 0.8rem;
  margin: 0;
  padding-top: 0.1em;
}

.logbook-photo-verified-icon {
  z-index: 200;
  position: absolute;
  top: -10px;
  right: -10px;
  width: 24px;
}

.edit-photo-btn-wr,
.add-log-photo-wr {
  position: relative;
  overflow: hidden;
  width: 100%;
  aspect-ratio: 6/6; 
  border-radius: 5px;
  cursor: pointer;
}

.edit-log-photo {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

#transfer-modal {
  background-color: #fff;
}

.add-new-log-wr,
.export-log-wr,
.transfer-log-wr {
  height: 200px;
  border: 1px solid var(--grey-4);
  border-radius: 8px;
  background-color: inherit;
}

.export-log-btn,
.add-new-log-btn, 
.transfer-log-btn {
  color: var(--grey-3);
  font-weight: 400;
  position: relative;
  margin: 0 auto;
  margin-top: 50px;
}


.export-log-btn:hover,
.add-new-log-btn:hover,
.transfer-log-btn:hover {
  color: var(--grey-2);
}

.logbook-log-btns-wr {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  max-height: 400px;
}

.add-new-log-wr button,
.export-log-wr button, 
.transfer-log-wr button {
  background-color: var(--grey-6);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 1em;
}

.edit-log-photo-overlay {
  background-color: var(--nearly-black);
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 0.8;
  cursor: pointer;
}

.edit-log-photo-overlay:hover {
  background-color: #2b2c37;
}

.enlarged-bike-img {
  max-width: 100%;
  max-height: 100%;
  margin-top: 1em;
  border-radius: 8px;
}

.export-icon,
.add-log-icon,
.transfer-log-icon {
  width: 35px;
  margin-right: 0.2em;
}

.add-new-log-wr {
  display: none;
}

.qr-code-icon {
  max-width: 60px;
}

/* ============================================================
   Modals
============================================================ */

/* Modal Overlay */
.modal-overlay {
  z-index: 1000;
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: black;
  opacity: 0.5;
}

/* Modal Wrapper */
.modal-wr,
.auth-inner {
  z-index: 1000;
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  background-color: var(--grey-5);
  width: 90%;
  max-width: 400px;
  max-height: 90vh;
  overflow-y: auto;
  padding: 2em 1em;
  box-sizing: border-box;
  border-radius: 8px;
}

.auth-inner {
  padding: 3em 1.5em;
  max-width: 550px;
}

.modal-wr .header-wr {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.overlap {
  z-index: 2000;
}

.err-msg {
  color: var(--destructive-red);
  margin-top: 1.5em;
  opacity: 1;
}

.model-err {
  margin: 0;
  padding: 0;
}

.modal-info {
  margin: 2em 0 1.5em 0;
}

.modal-year-wr {
  display: flex;
  flex-direction: column;
  margin-bottom: 1em;
}

#add-new-log-wr,
#edit-log-wr {
  display: none;
}

.modal-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1em;
  width: 100%;
}

.modal-delete-btn {
  background-color: var(--destructive-red);
  font-size: 0.8rem;
  color: white;
  padding: 3px 5px;
  border-radius: 3px;
}

.modal-delete-btn:hover {
  background-color: var(--destructive-red-hover);
}

.modal-date-wr {
  margin-bottom: 1em;
}

.modal-mileage-cost-wr {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  max-width: 100%;
  margin-bottom: 1em;
}

.modal-mileage-wr,
.modal-cost-wr {
  max-width: 100%;
}

.modal-shop-wr {
  margin-bottom: 1em;
}

.modal-input,
input[type="date"],
.modal-add-task-input,
.modal-notes,
.feedback-textarea,
.dropdown-list,
.task-item,
.modal-edit-task-input,
.dash-input {
  width: 100%;
  box-sizing: border-box;
  background-color: var(--grey-5);
  border: 1px solid rgb(87, 87, 87);
  border-radius: 3px;
  padding: 0.4em 0.7em;
  color: white;
  opacity: 0.8;            /* Ensure the color is fully applied, especially in some browsers */
  outline: none;
  font-size: 1.1rem;
}

input::placeholder {
  color: white;           /* Change the color of the placeholder text */
  opacity: 0.8;            /* Ensure the color is fully applied, especially in some browsers */
}

.feedback-textarea {
  margin-top: 1em;
}

.modal-wr h3 {
  margin-bottom: 0.5em; /* Corrected from 10.,5em */
}

input[type="date"] {
  width: auto;
}

input[type="date"]:focus,
.modal-input:focus {
  border-color: white;
}

.modal-tasks-wr {
  margin-bottom: 1em;
}

.task-wr,
.task-input-wr {
  display: flex;
  justify-content: space-between;
  gap: 1em;
  margin-bottom: 0.5em;
  margin-top: 1em;
  text-align: start;
}


.modal-notes-wr {
  margin-bottom: 1em;
}

.modal-notes {
  width: 100%;
  height: 100px;
}

.modal-photos-wr {
  margin-bottom: 1em;
}

.add-photo-btn,
.edit-photo-btn {
  background-color: var(--grey-5);
  padding: 0;
  margin-left: -5px;
  margin-top: -5px;
}

.delete-img-btn {
  position: relative;
  margin: 0;
  box-sizing: border-box;
  padding: 0;
}

.delete-img-icon {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 50px;
  transform: translate(-50%, -50%);
}

.modal-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1em;
  margin-top: 1.5em;
}

option:checked,
option:hover,
option:focus {
  background-color: var(--main-orange);
}

option {
  background-color: var(--grey-6);
  border: none;
}

.add-bike-input-wr {
  display: flex;
  flex-direction: column;
}

#bike-year {
  margin-bottom: 1em;
}

.add-bike-input-wr input {
  margin-bottom: 1em;
}

#cannot-find-btn,
#add-bike-btn,
#add-custom-bike-btn {
  margin-top: 1em;
}

#add-bike-btn,
#find-bike-btn {
  display: flex;
  justify-content: center;
  align-items: center;
}

label[for="bike-model"] {
  margin-bottom: 1em;
}

#add-bike-btn img {
  width: 20px;
}

.delete-confirm-wr {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 0.5em;
}

.delete-confirm-wr h2 {
  margin-bottom: 0.4em;
  text-align: center;
}

.delete-confirm-wr .close-icon {
  align-self: flex-end;
}

.forgot-password {
  margin-top: 1em;
}

.forgot-password a {
  font-size: inherit;
}

.continue-without-link {
  color: var(--grey-1);
  text-decoration: none;
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.verified-photo-title-wr {
  display: flex;
  align-items: center;
  gap: 0.5em;
}

.verified-photo-title-wr img {
  width: 25px;
}

.verified-photo-msg {
  color: var(--green);
}

.enlarge-photo-wr {
  margin-bottom: 1em;
}

#add-vin-input {
  margin-bottom: 0.5em;
}

.login-with-google-btn {
  transition: background-color .3s, box-shadow .3s;
  max-width: 250px;
  padding: 12px 16px 12px 42px;
  border: none;
  border-radius: 3px;
  box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
  margin: 0 auto;
  margin-top: 2em;
  color: #757575;
  font-size: 14px;
  font-weight: 500;
  font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen,Ubuntu,Cantarell,"Fira Sans","Droid Sans","Helvetica Neue",sans-serif;
  
  background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTgiIGhlaWdodD0iMTgiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PGcgZmlsbD0ibm9uZSIgZmlsbC1ydWxlPSJldmVub2RkIj48cGF0aCBkPSJNMTcuNiA5LjJsLS4xLTEuOEg5djMuNGg0LjhDMTMuNiAxMiAxMyAxMyAxMiAxMy42djIuMmgzYTguOCA4LjggMCAwIDAgMi42LTYuNnoiIGZpbGw9IiM0Mjg1RjQiIGZpbGwtcnVsZT0ibm9uemVybyIvPjxwYXRoIGQ9Ik05IDE4YzIuNCAwIDQuNS0uOCA2LTIuMmwtMy0yLjJhNS40IDUuNCAwIDAgMS04LTIuOUgxVjEzYTkgOSAwIDAgMCA4IDV6IiBmaWxsPSIjMzRBODUzIiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNNCAxMC43YTUuNCA1LjQgMCAwIDEgMC0zLjRWNUgxYTkgOSAwIDAgMCAwIDhsMy0yLjN6IiBmaWxsPSIjRkJCQzA1IiBmaWxsLXJ1bGU9Im5vbnplcm8iLz48cGF0aCBkPSJNOSAzLjZjMS4zIDAgMi41LjQgMy40IDEuM0wxNSAyLjNBOSA5IDAgMCAwIDEgNWwzIDIuNGE1LjQgNS40IDAgMCAxIDUtMy43eiIgZmlsbD0iI0VBNDMzNSIgZmlsbC1ydWxlPSJub256ZXJvIi8+PHBhdGggZD0iTTAgMGgxOHYxOEgweiIvPjwvZz48L3N2Zz4=);
  background-color: white;
  background-repeat: no-repeat;
  background-position: 15px 14px;
  

  &:active {
    background-color: #eeeeee;
  }

  
  &:focus {
    outline: none;
    box-shadow: 
    0 -1px 0 rgba(0, 0, 0, .04),
    0 2px 4px rgba(0, 0, 0, .25),
    0 0 0 3px #c8dafc;
  }
  
  &:disabled {
    filter: grayscale(100%);
    background-color: #ebebeb;
    box-shadow: 0 -1px 0 rgba(0, 0, 0, .04), 0 1px 1px rgba(0, 0, 0, .25);
    cursor: not-allowed;
  }
  
  .login-with-google-btn:hover {
    background-color: var(--grey-3);
  }
}

.transfer-modal-wr {
  position: relative
}

.transfer-modal-wr .close-icon {
  position: absolute;
  top: 0;
  right: 0;
  width: 17px;
}

.firebase-auth-img-wr {
  width: 100%;
  display: flex;
  justify-content: center;
}


.firebase-auth-img {
  width: 190px;
}

.close-icon {
  width: 28px;
}

.book-moc {
  width: 200px;
  margin-bottom: 0.5em;
}
/* ============================================================
Bike Specs Modal
============================================================ */

.table {
  margin-top: 1em;
}

.table-row {
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 0.3em;
}

.bg {
  background-color: var(--grey-6);
}

.row-header {
  color: white;
  font-weight: 700;
}

.row-value {
  color: var(--main-orange);
  text-align: right;
  opacity: 1;
}

/* ============================================================
  Feedback Modal
============================================================ */

.feedback-form h3 {
  margin-top: 1em;
  margin-bottom: 1em;
}

.feedback-message-wr {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.feedback-icon {
  width: 120px;
} 

.feedback-textarea {
  height: 250px;
  margin-bottom: 0.5em;
  font-size: 1rem;
}

/* ============================================================
   Tooltip
============================================================ */

.tooltip-wr {
  display: none;
  position: relative;
  top: 38px;
  left: 0.5em;
  margin-right: 1em;
  background-color: var(--main-orange-hover);
  box-sizing: border-box;
}

.tooltip-info {
  color: var(--nearly-black);
  padding: 1em;
  font-size: 1.1rem;
  line-height: 1.4em;
  letter-spacing: 0.063rem;
}

/* ============================================================
   Fallback Page
============================================================ */

.main-fallback-wr {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 8em;
}

.fallback-wr {
  margin-top: -8em;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 1em;
  max-width: 900px;
  gap: 1.4em;
}

.fallback-wr .primary-btn {
  max-width: 500px;
}

.fallback-wr p {
  text-align: center;
}

.fallback-page-img {
  width: 100%;
  max-width: 900px;
  border-radius: 13px;
  object-fit: cover; 
  height: 300px;
  
}
/* ============================================================
   Add Photo Modal
============================================================ */

.video-frame {
  margin-bottom: 0.5em;
}

.flex-btns-wr {
  display: flex;
  gap: 1em;
}

/* ============================================================
   Loading Overlay
============================================================ */

.loading-overlay-wr {
  z-index: 3000;
  width: 100vw;
  height: 100vh;
  background-color: var(--grey-5);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

/* ============================================================
   Dashboard
============================================================ */

.dash-wr {
  margin: 1em 0.5em 0.5em 0.5em;
}

.dash-bike-hero,
.dash-add-photo-wr {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.dash-add-photo {
  width: 80px;
  margin-bottom: 0.5em;
}

.dash-title {
  color: var(--grey-1);
}

.add-vin-btn {
  background: none;
  color: var(--green);
  margin-top: 1em;
}

.vin-number {
  margin-top: 1em;
}

.reg-input {
  background-color: var(--main-orange);
  padding: 0.2em;
  font-family: 'reg-font';
  font-size: 1.5625rem;
  text-align: center;
  border: none;
  border-radius: 8px;
  width: 100%;
  max-width: 250px;
  outline: none;
  margin-top: 0.5em;
}

.reg-input::placeholder {
  color: var(--grey-4);
}

.dash-manual-specs-wr {
  display: flex;
  justify-content: space-between;
  margin-top: 1.5em;
  width: 100%;
  align-items: center;
}

.add-bike-photo-btn {
  background: none;
  margin-bottom: 1em;
}

.add-bike-photo-btn img {
  width: 100px;
}

.bike-spec-btn {
  background: none;
  display: flex;
  align-items: center;
  gap: 0.3em;
  color: var(--grey-1);
  font-weight: 400;
  text-decoration: underline;
}

.manual-link {
  display: flex;
  gap: 0.3em;
  text-decoration: underline;
  color: var(--main-orange);
}

.dash-photo-wr {
  width: 120px;
  height: 120px;
  border-radius: 50%;
  overflow: hidden;
  margin-bottom: 1em;
  border: 3px solid var(--grey-3);
}

.dash-photo-wr img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
}

.dash-edit-btn-wr {
  margin-top: 1.5em;
  width: 100%;
  display: flex;
  justify-content: center;
}

.dash-edit-btn {
  letter-spacing: 0.4px;
} 

.dash-main input {
  text-align: center;
  display: flex;
  justify-content: center;
}

.dash-edit-btn,
.dash-save-btn,
.dash-edit-btn-desktop,
.dash-save-btn-desktop {
  background-color: var(--main-orange);
  border-radius: 5px;
  font-weight: 800;
  display: flex;
  align-items: center;
  gap: 0.3em;
}

.dash-edit-save-btn-wr {
  display: flex;
  justify-content: center;
  margin-bottom: 0.5em;
}

.dash-tile {
  width: 100%;
  background-color: var(--grey-5);
  border-radius: 8px;
  padding:1.5em 0.7em;
}

.dash-main {
  margin-top: 1em;
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.dash-tile-header {
  position: relative;
  display: flex;
  width: 100%;
  justify-content: center;
  margin-bottom: 1.5em;
}

.dash-mot-tax-expiry-wr .dash-tile-header {
  margin-bottom: 0.5em
}

.dash-tile-header img {
  position: absolute;
  top: 0;
  left: 0;
}

.dash-mot-tax-expiry-wr .dash-tile-main {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.7em;
}

.dash-prop {
  color: var(--grey-1);
}

.dash-suspension-clickers-wr {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0.7em;
}

.dash-input {
  width: 48px;
}

#dash-add-task-btn,
#dash-save-task-btn {
  max-width: 300px;
}

.modal-todoList-wr {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
}

.modal-todoList-wr .task-input-wr,
.modal-todoList-wr .task-list-wr {
  width: 100%;
  margin: 0;
}

.modal-todoList-wr .task-input-wr {
  margin-top: 0.5em;
  margin-bottom: 0.5em;
}

.dash-todo-header {
  align-self: self-start;
  margin-bottom: 0.5em;
}

.add-vin-btn:hover {
  color: var(--green-hover);
}

.reg-input:hover {
  background-color: var(--main-orange-hover);
}

.dash-app-icon-wr {
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1em;
  margin-top: 1em;
  margin-bottom: 1em;
}

.dash-app-icon {
  width: 80px;
}

/* ============================================================
   Utility Classes
============================================================ */

.disabled-text {
  color: var(--grey-3);
}

.secondary-btn,
.primary-btn {
  width: 100%; 
  padding: 0.4em;
  margin-top: 0.5em;
  border-radius: 25px;
  color: var(--grey-6);
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.2em;
}

.button-icon {
  width: 20px;
}

.primary-btn {
  background-color: var(--main-orange);
}

.primary-btn:hover {
  background-color: var(--main-orange-hover);
}

.secondary-btn {
  background-color: var(--grey-2);
}

.secondary-btn:hover {
  background-color: var(--grey-1);
}

.fallback-msg {
  margin-top: 1em;
}

.no-marg {
  margin: 0;
}

.center-text {
  text-align: center;
}

.bold {
  color: var(--main-orange);
  font-weight: 600;
}

.primary-link {
  color: var(--main-orange);
  text-decoration: none;
  font-weight: 600;
}

.primary-link:hover {
  color: var(--main-orange-hover);
}

.secondary-link {
  color: var(--grey-2);
}

.secondary-link:hover {
  color: var(--grey-1);
}

.grey-3 {
  color: var(--grey-3);
}

.flex-around {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-around;
}

.selected-link {
  color: var(--main-orange);
}

.verified {
  border: 2px solid var(--green);
}

.verified-bg {
  background-color: var(--green);
}

.verified-log li::marker {
  color: var(--green); 
}

.flex-col-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1em;
}

.qr-code-wr {
  width: 100%;
  max-width: 400px;
}

.qr-code, .qr-code-wr {
  width: 100%;
  height: auto;
}
/* ============================================================
   About Page
============================================================ */

/* Hide scrollbar for modern browsers */
.no-scrollbar {
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* IE and Edge */
}

.no-scrollbar::-webkit-scrollbar {
  display: none; /* Chrome, Safari, and Opera */
}

/* ============================================================
   Donate Page
============================================================ */

.donate-me-img {
  width: 150px;
}

.buy-me-a-beer-btn {
  width: 180px;
  margin-top: 0.5em;
  margin-bottom: 1em;
}



/* ============================================================
   Media Queries
============================================================ */

@media (min-width: 400px) { 
  header {
    grid-template-columns: 1fr 1fr;
  }

  .logo {
    width: 150px;
  }
}


/* Medium Screens (Min Width: 600px) */
@media (min-width: 600px) {

  /* Login */
  .firebase-auth-img {
    width: 220px;
  }
  
  /* Typography */
  html {
    font-size: 19px;
  }

  /* Header */
  .logo {
    width: 150px;
  }

  .info-icon {
    display: block;
    width: 20px;
  }

  header .heading-xl {
    margin: 0;
  }

  /* Sidebar */
  .sidebar-wr {
    width: 60vw; /* 60% of the viewport width */
    padding: 1em;
    padding-top: 0;
  }

  .sidebar-wr.show {
    transform: translateX(165%); /* Push it out by 60% of the viewport width */
  }

  .close-sidebar-icon {
    display: none;
  }

  .sidebar-wr .home-btn,
  .sidebar-wr .logbook-btn {
    font-size: 1rem;
  }

  .sidebar-wr .bike-btn-row, 
  .sidebar-wr .theme-selector-signout-wr {
    width: 100%;
  }

  /* Misc */
  .main-wr {
    margin-top: 3.5em;
  }

  /* Maintenance Log */
  .logs-grid {
    grid-template-columns: 1fr 1fr;
    gap: 1em;
  }

  .log-wr {
    height: fit-content;
    min-height: 300px;
  }



  .add-new-log-wr,
  .export-log-wr,
  .transfer-log-wr {
    height: 300px;
    width: 100%;
  }

  .export-log-btn,
.add-new-log-btn, 
.transfer-log-btn {
  margin-top: 90px;
}

  .modal-wr {
    max-width: 500px;
  }

  .add-new-log-wr {
    display: block;
  }

  .mobile-add-log-btn-wr {
    display: none;
  }

  .logbook-log-btns-wr {
    display: flex;
  }

  .transfer-log-wr,
  .add-new-log-wr,
  .export-log-wr {
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0.5em;
  }

  .add-new-log-wr button,
  .export-log-wr button,
  .transfer-log-wr button {
    gap: 0.1em;
  }

  .add-new-log-wr p,
  .export-log-wr p,
  .transfer-log-wr p {
    font-size: 0.9rem;
  }

  .export-log-btn,
  .add-new-log-btn, 
  .transfer-log-btn {
    margin: 0;
    display: flex;
  }
  .logbook-log-btns-wr {
    display: grid;
    grid-template-rows: 1fr 1fr;
    min-height: 200px;
  }

  /* About */
  .about-wr .example-log {
    max-width: 350px;
  }

  .about-center-div {
    background-color: var(--grey-5);
    margin: -0.5em;
  }

  .about-wr {
    background-color: var(--grey-6);
    margin-top: -0.5em;
    padding: 1em;
    padding-top: 2em;
  }

  /* Dashboard */
  .dash-input {
    width: 50px;
  }

}

/* Split dashboard into a 2 column layout */

@media (min-width: 730px) {
  
    /* Dashboard */

    .dash-wr {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
  
    .dash-wr form {
      width: 100%;
    }
    .dash-bike-hero {
      max-width: 400px;
    }
  
    .dash-main {
      display: grid;
      grid-template-columns: 1fr 1fr;
      gap: 1em;
    }
  
    .dash-tile{
      height: min-content;
    }

    .dash-tyre-pressures-wr {
      height: 100%;
    }

    .add-new-log-wr p,
    .export-log-wr p,
    .transfer-log-wr p {
      font-size: 1.2rem;
    }
  
    .add-new-log-wr button,
    .export-log-wr button,
    .transfer-log-wr button {
      gap: 0.5em;
    }

    /* Sidebar */
    .sidebar-wr {
      padding: 2em;
      padding-top: 0;
    }

}



/* Large Screens (Min Width: 1200px) */
@media (min-width: 1200px) {
  
  body {
    font-size: 24px;
  }

  .logo {
    width: 200px;
  }

  p, li, button, a {
    font-size: 1.2rem;
    line-height: 1.7rem;
  }

  .heading-l {
    font-size: 1.2rem;
  }

  .heading-m {
    font-size: 1.1rem;
  }

  .heading-xl {
    font-size: 1rem;
  }

  /* Main Content */
  .main-wr {
    margin-left: 400px;
    margin-top: 2.6em;
  }

  /* Sidebar */
  .sidebar-wr {
    position: fixed;
    display: flex;
    width: 400px;
    top: 0;
    left: 0;
    padding:  0 1em 1em 1em;
    height: 100vh;
    transition: none;
    border-right: 2px solid var(--grey-4);
  }

  .sidebar-wr.show {
    transform: none;
  }

  .sidebar-wr .logo {
    display: block;
    margin-left: 1em;
    margin-top: 1.5em;
    margin-bottom: 3em;
    width: 180px;
  }

  .sidebar-wr .bike-count {
    margin-left: 1.5em;
    font-size: 1rem;
  }

  .sidebar-wr .bike-btn-row {
    width: 90%;
  }

  .sidebar-nav-btns {
    margin-top: 0.8em;
  }


  /* Header */
  header {
    display: flex;
    padding-left: 17.5em;
    height: 87px;
  }

  header .left-col {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  header .right-col {
    display: none;
  }

  header .menu-icon {
    display: none;
  }

  header .info-icon {
    display: block;
  }

  .header-subtitle {
    font-size: 1.25rem;
  }

  .header-title {
    margin-top: 0.4em;
  }

  /* Maintenance Log */
  .logs-grid {
    grid-template-columns: 1fr 1fr;
  }

  .log-wr {
    height: fit-content;
    min-height: 400px;
    padding: 1.2em;
  }

  .edit-btn {
    right: 1.5em;
    top: 1.5em;
    font-size: 0.838rem;
    padding: 0 0.3em;
  }

  /* Tooltip */
  .tooltip-wr {
    top: 33px;
  }

  .export-icon,
  .add-log-icon,
  .transfer-log-icon {
    width: 50px;
  }


  .verified-log-icon {
    width: 30px;
  }

  /* About page */
  .about-wr {
    max-width: 1000px;
    padding: 3em;
  }

  .about-bike-name {
    font-size: 1.3rem;
  }

  .sub-heading-orange .verified-icon {
    width: 35px;
  }

  .about-wr .logo {
    width: 200px;
  }

  .copyright-text {
    font-size: 0.8rem;
  }

  /* Donate */

  .donate-me-img {
    width: 200px;
  }

  .buy-me-a-beer-btn {
    width: 220px;
  }
}

/* Extra Large Screens (Min Width: 1500px) */
@media (min-width: 1400px) {
  /* Dashboard */
  .dash-wr {
    display: grid;
    grid-template-columns: 400px 1fr;
    position: relative;
  }

  .dash-bike-hero {
    height: 100%;
    width: 100%;
    justify-content: start;
    align-items: center;
    padding: 0 2em 0 2em;
  }

  .dash-photo-wr {
    height: 110px;
    width: 110px;
  }

  .dash-title {
    font-size: 1.4em;
    max-width: 230px;
  }

  .reg-input {
    padding: 0em;
    font-size: 1.1em;
    max-width: 180px;  
  }

  .add-vin-btn,
  .vin-number {
    margin-top: 0.5em;
  }

  .dash-manual-specs-wr {
    margin-top: 1em;
  }

  .dash-main-wr {
    margin-top: -1em;
  }

  .dash-main-wr {
    display: flex;
    flex-direction: column-reverse;
    margin-top: -1.5em;
  }

  .dash-edit-btn-wr {
    justify-content: end;
    margin-top: 1em;
  }

    /* Maintenance Log */
    .logs-grid {
      grid-template-columns: 1fr 1fr 1fr;
    }

    .logbook-log-btns-wr {
      min-height: 400px;
    }

}

