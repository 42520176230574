@import url("https://fonts.googleapis.com/css?family=Fira+Sans:400,500,600,700,800");

* {
  box-sizing: border-box;
}

body,
html,
.App,
#root,
.auth-wrapper {
  width: 100%;
  height: 100%;
}



.auth-wrapper {
  background-color: var(--grey-6);
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: left;
}

.auth-inner {
  width: 90%;
  margin: auto;
  background: var(--grey-5);
  box-shadow: 0px 14px 80px rgba(34, 35, 58, 0.2);
  padding: 40px 55px 45px 55px;
  transition: all 0.3s;
}

.auth-logo {
  max-width: 200px;
}

.auth-inner .modal-header {
  display: flex;
  justify-content: center;
  margin-bottom: 1.5em;
}

.auth-inner .logo {
  width: 150px;
}

.auth-inner h2 {
  margin-bottom: 2em;
}

.auth-inner .free-ebook-title {
  margin-top: 1em;
  margin-bottom: 1em;
}

.auth-inner form {
  display: flex;
  flex-direction: column;
  gap: 1em;
}

.auth-footer {
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.auth-inner a {
  text-decoration: none;
}



.auth-wrapper .form-control:focus {
  border-color: #167bff;
  box-shadow: none;
}

.auth-wrapper h3 {
  margin: 0;
  line-height: 1;
  padding-bottom: 20px;
}

.custom-control-label {
  font-weight: 400;
}

.forgot-password,
.forgot-password a {
  font-size: 13px;
  padding-top: 10px;
  color: #7f7d7d;
  margin: 0;
}

.forgot-password a {
  color: var(--main-orange);
}

.forgot-password a:hover {
  color: var(--main-orange-hover);
}
.continue-p{
  font-size: 12px;
    color: #b2b2b2;
    font-weight: 600;
    margin-top: 1rem;
    text-align: center;
}

.modal-links-wr {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin: 2em 0.5em 0 0.5em;
}
